import defaults from './defaults';

export default function(entry) {
	return entry ? {
		id: 								entry.id,
		title:							entry.title,
		file:								entry.file,
		type:								entry.type,
		lyrics:							entry.lyrics,
		isDeleted:					entry.isDeleted,
		licenses:						entry.licenses
	} : defaults();
}