<template>
	<div v-if="loading">
		Загрузка данных&hellip;
	</div>
	<div v-else>
		<div>
			<h1 class="section-title">
				<slot name="heading" :isNewEntry="isNewEntry" />
			</h1>
		</div>
	
		<form ref="data-form" @submit.prevent="submit">
			<div class="container-fluid">
				<div class="row mb-3 g-0">
					<div class="col-12">
						<slot name="form" :resource="entry" :hints="showHints" />
					</div>
				</div>

				<div class="row">
					<div class="col-6">
						<button type="submit" class="btn btn-save-item">
							{{ isNewEntry ? 'Добавить' : 'Сохранить' }}
						</button>
						
						&nbsp;&nbsp;&nbsp;

						<confirmation-modal
							v-if="!isNewEntry"
							btn-title="В корзину"
							btn-class="btn-delete-item"
							title="Подтверждение операции"
							@confirm="remove"
						>
							<p>
								Запись &laquo;<strong>{{ entry.title }}</strong>&raquo; будет перенесена в корзину. Находящиеся в корзине записи скрыты на сайте, редактирование таких записей невозможно, а доступ к связанным с ним файлам ограничен. Такие записи не учитываются в общей статистике сайта. Помещённую в корзину запись возможно в любое время восстановить, после чего она будет сразу снова доступна, или окончательно удалить, после чего все сведения о ней, в том числе изображения и прочие файлы, связанные с ней, будут безвозвратно стёрты.
							</p>

							<p>
								Вы действительно хотите продолжить?
							</p>
						</confirmation-modal>

						&nbsp;&nbsp;&nbsp;
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import ConfirmationModal from '../generic/modal/ConfirmationModal.vue';
	import cloneDeep from 'lodash.clonedeep';
	import { toast } from 'vue3-toastify';
	import 'vue3-toastify/dist/index.css';
	import Toggle from './Toggle.vue';

	export default {
		props: {
			id: [String, null],
			endpoints: Object
		},

		data () {
			return {
				loading: true,
				loadedEntry: { },
				entry: { },
				updated: { },
				showHints: true
			}
    },

    mounted() {
			this.retrieve();
    },

		methods: {
			async uploadCovers() {
				// If entry has covers, upload them if need be.
				if('covers' in this.entry) {
					const uploadedCovers = new Promise((resolve, reject) => {
						const requests = [];

						const { covers, uploaded } = this.entry.covers;

						if(!uploaded) {
							for (const [resolution, url] of Object.entries(covers)) {
								requests.push(
									this.$helpers.sendUploadRequest(this.endpoints.upload, url)
										.then(response => response.filename)
										.then(uploadedFile => {
											covers[resolution] = uploadedFile;
										})
								);
							}
						}

						Promise.allSettled(requests)
							.then(response => {
								resolve();
							})
							.catch(error => {
								reject(error);
							})
					});

					await uploadedCovers;

					console.info('Covers uploaded successfully.');
				}

				return true;
			},

			async uploadNewsMedia() {
				// If entry has media, upload them if needed.
				if ('media' in this.entry) {
					const uploadPromises = [];

					for (const mediaItem of this.entry.media) {
						const { blob } = mediaItem;
						// const thumbnail = { };
						
						// if (Object.hasOwn(mediaItem, 'thumbnail')) {
						// 	thumbnail = mediaItem.thumbnail;
						// }
						// // const { blob, thumbnail } = mediaItem;

						const uploadId = `upload_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

						// if (thumbnail && thumbnail.blob && thumbnail.blob.size > 0) {
						// 	const thumbnailUploadId = `${uploadId}_thumbnail`;
						// 	const thumbnailUploadPromise = await this.$helpers.sendBlobUploadRequest(this.endpoints.uploadChunks, thumbnail.blob, thumbnailUploadId);

						// 	mediaItem.thumbnail.url = thumbnailUploadId;

						// 	uploadPromises.push(thumbnailUploadPromise);
						// } else if(thumbnail) {
						// 	mediaItem.thumbnail.url = mediaItem.thumbnail.url.substring(mediaItem.thumbnail.url.lastIndexOf('/') + 1).split('.')[0];
						// } else {
						// 	mediaItem.thumbnail.url = null;
						// }

						if (blob && blob.size > 0) {
							const uploadPromise = await this.$helpers.sendBlobUploadRequest(this.endpoints.uploadChunks, blob, uploadId);

							mediaItem.url = uploadId;

							uploadPromises.push(uploadPromise);
						} else {
							mediaItem.url = mediaItem.url.substring(mediaItem.url.lastIndexOf('/') + 1).split('.')[0];
						}
					}

					try {
						const results = await Promise.allSettled(uploadPromises);

						// Logging the results
						results.forEach(({ status, reason }, index) => {
							if (status === 'fulfilled') {
								console.info(`Media ${index} uploaded successfully.`);
							} else {
								console.error(`Media ${index} failed to upload:`, reason);
							}
						});

						console.info('All media upload attempts completed.');
					} catch (error) {
						console.error('An error occurred during media uploads:', error);
						throw error; // Rethrow or handle the error as needed
					}
				}
				return true;
			},

			async uploadStepImages() {
				// If entry has steps, upload them if need be.
				if('steps' in this.entry) {
					const uploadedStepImages = new Promise((resolve, reject) => {
						const requests = [];

						for(const { image } of this.entry.steps) {
							const { url, uploaded } = image;

							if(uploaded)
								continue;

							requests.push(
								this.$helpers.sendUploadRequest(this.endpoints.upload, url)
									.then(response => response.filename)
									.then(uploadedFile => {
										image.url = uploadedFile;
									})
							);
						}

						Promise.allSettled(requests)
							.then(response => {
								resolve();
							})
							.catch(error => {
								reject(error);
							})
					});

					await uploadedStepImages;

					console.info('Step images uploaded successfully.');
				}

				return true;
			},

			async uploadDemos() {
				if('demos' in this.entry) {
					const uploadedDemoFiles = new Promise((resolve, reject) => {
						const requests = [];

						for(const [key, demo] of this.entry.demos.entries()) {
							const { image, file } = demo;

							if(!image.uploaded) {
								requests.push(
									this.$helpers.sendUploadRequest(this.endpoints.upload, image.url)
										.then(response => response.filename)
										.then(uploadedFile => {
											this.entry.demos[key].cover = uploadedFile;
										})
								);
							}

							requests.push(
								this.$helpers.sendUploadRequest(this.endpoints.upload, file)
									.then(response => response.filename)
									.then(uploadedFile => {
										this.entry.demos[key].file = uploadedFile;
										console.log(this.entry.demos[key]);
									})
							);
						}

						Promise.allSettled(requests)
							.then(response => {
								resolve();
							})
							.catch(error => {
								reject(error);
							})
					});

					await uploadedDemoFiles;

					console.info('Demo tracks uploaded successfully.');
				}

				return true;
			},

			async uploadDownloadables() {
				if('downloadables' in this.entry) {

				}

				return true;
			},

			async uploadFile() {
				
				if('file' in this.entry) {
					const uploadedFiles = new Promise((resolve, reject) => {
						const requests = [];

						requests.push(
							this.$helpers.sendUploadRequest(this.endpoints.upload, this.entry.file)
								.then(response => response.filename)
								.then(uploadedFile => {
									this.entry.file = uploadedFile;
								})
						);

						Promise.allSettled(requests)
							.then(response => {
								resolve();
							})
							.catch(error => {
								reject(error);
							})
					});

					await uploadedFiles;

					console.info('File uploaded successfully.');
				}

				return true;
			},

			async retrieve(id) {
				try {
					this.entry = await this.endpoints.entry(id ?? this.id);
					this.loadedEntry = cloneDeep(this.entry);
					this.loading = false;
				} catch(error) {
					console.log(error);
					console.error(`Entry with the id ${this.id} not found`);
				}
			},

			async remove() {
				try {
					await this.endpoints.trash(this.entry.id);

					window.location.href = route('panel-vue.products.list');
				} catch(error) {
					console.error(error);
				}
			},

			async submit() {
				const toastId = toast.loading(
					'Загрузка изображений обложек...',
					{
						position: toast.POSITION.TOP_RIGHT,
					},
				);

				await this.uploadCovers();

				// toast.update(toastId, {
				// 	render() {
				// 		return 'Загрузка демо-композиций...';
				// 	}
				// });

				// await this.uploadDemos();

				toast.update(toastId, {
					render() {
						return 'Загрузка файла...';
					}
				});

				await this.uploadFile();

				toast.update(toastId, {
					render() {
						return 'Загрузка изображений...';
					}
				});

				await this.uploadStepImages();

				toast.update(toastId, {
					render() {
						return 'Загрузка галереи...';
					}
				});

				await this.uploadNewsMedia();

				toast.update(toastId, {
					render() {
						return 'Сохранение данных...';
					}
				});

				if(this.isNewEntry) {
					// Add.
					try {
						const id = await this.endpoints.store(this.entry).then(response => response.id);

						this.retrieve(id);

						toast.update(toastId, {
							render() {
								return 'Добавление прошло успешно.';
							},
							isLoading: false,
							type: toast.TYPE.SUCCESS,
							autoClose: 5000,
						});
					} catch(failure) {
						if(failure.response) {
							if(failure.response.status === 422) {
								toast.update(toastId, {
									render() {
										return 'Ошибка 422: Форма не прошла валидацию. Причины:'
										+ Object.values(failure.response.data.errors).map(item => item.join(',')).join(',')
										+ '.';
									},
									isLoading: false,
									type: toast.TYPE.ERROR,
									closeButton: true
								});
							} else if(failure.response.status === 500) {
								toast.update(toastId, {
									render() {
										return 'Произошла ошибка сервера. Обратитесь к разработчику для решения проблемы.';
									},
									isLoading: false,
									type: toast.TYPE.ERROR,
									closeButton: true
								});
							} else {
								toast.update(toastId, {
									render() {
										return 'Произошла неизвестная ошибка. Обратитесь к разработчику для решения проблемы.';
									},
									isLoading: false,
									type: toast.TYPE.ERROR,
									closeButton: true
								});
							}
						} else {
							toast.update(toastId, {
								render() {
									return 'Произошла неизвестная ошибка. Обратитесь к разработчику для решения проблемы.';
								},
								isLoading: false,
								type: toast.TYPE.ERROR,
								closeButton: true
							});
						}
					}
				} else {
					// Edit
					try {
						await this.endpoints.update(this.entry.id, this.loadedEntry, this.entry).then(response => response.id);
						
						this.retrieve(this.entry.id);

						toast.update(toastId, {
							render() {
								return 'Изменение прошло успешно.';
							},
							isLoading: false,
							type: toast.TYPE.SUCCESS,
							autoClose: 5000,
						});
					} catch(failure) {
						if(failure.response.status === 422) {
							toast.update(toastId, {
								render() {
									return 'Ошибка 422: Форма не прошла валидацию. Причины:'
									+ Object.values(failure.response.data.errors).map(item => item.join(',')).join(',')
									+ '.';
								},
								isLoading: false,
								type: toast.TYPE.ERROR,
								closeButton: true
							});
						} else if(failure.response.status === 500) {
							toast.update(toastId, {
								render() {
									return 'Произошла ошибка сервера. Обратитесь к разработчику для решения проблемы.';
								},
								isLoading: false,
								type: toast.TYPE.ERROR,
								closeButton: true
							});
						} else {
							toast.update(toastId, {
								render() {
									return 'Произошла неизвестная ошибка. Обратитесь к разработчику для решения проблемы.';
								},
								isLoading: false,
								type: toast.TYPE.ERROR,
								closeButton: true
							});
						}
					}
				}
			}
    },

		computed: {
			isNewEntry() {
				return this.entry.id === null;
			}
		},

		components: {
			ConfirmationModal, Toggle
    }
	}
</script>

<style>
</style>