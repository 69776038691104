import defaults from './defaults';

export default function(entry) {
	console.log(entry);
	return entry ? {
		id: 								entry.id,
		blob:								(new Blob([], { type: entry.mimeType })),
		title:							entry.title,
		description:				entry.description,
		url:								entry.url
	} : defaults();
}