import { generateId } from "@/tools/nanoid";

export default function() {
	return {
		id: generateId(),
		blob: null,
		url: null,
		title: null,
		description: null
	}
}