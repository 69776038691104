<template>
	<div class="container entry-container rounded-3 p-4 my-4 shadow-sm">
		<div class="d-flex flex-row gap-3">
			<div class="flex-grow-1 d-flex flex-column justify-content-between">
				<div>
					<strong>
						{{ entry.item.title }}
					</strong>
				</div>
				
				<div class="d-flex flex-row gap-3 entry-footer">
					<div v-if="entry.video">
						<i class="fa-solid fa-video"></i>	
					</div>
				</div>
			</div>
			<div>
				<a
					class="btn btn-action-edit d-flex flex-row justify-content-center align-items-center gap-2" 
					type="button"
					:href="$helpers.linkTo(`license/${entry.id}`)"
				>
					<i class="fa-solid fa-pen-to-square"></i>
					<!-- Изменить -->
				</a>
				<br/><br/>
				<a
					class="btn btn-action-edit d-flex flex-row justify-content-center align-items-center gap-2" 
					type="button"
					target="_blank"
					href="#"
				>
					<i class="fa-solid fa-link"></i>
					Открыть
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	import ConfirmationModal from '../../../generic/modal/ConfirmationModal.vue';
	import { toast } from 'vue3-toastify';
	import 'vue3-toastify/dist/index.css';

  export default {
		props: {
			entry: {
				type: Object
			}
		},

    data () {
      return {
        entries: [],
				searchQuery: ''
      }
    },

    mounted() {
			
    },

    methods: {
			async hideEntry(entry) {
				const succeeded = await this.$api.downloadables.hide(entry.id);

				if(succeeded) {
					toast.success("Материал успешно скрыт.", {
						autoClose: 4000,
					});

					entry.isHidden = true;
				} else {
					toast.error("Не удалось выполнить запрос.", {
						autoClose: 4000,
					});
				}
			},

			async revealEntry(entry) {
				const succeeded = await this.$api.downloadables.reveal(entry.id);

				if(succeeded) {
					toast.success("Материал успешно показан.", {
						autoClose: 4000,
					});

					entry.isHidden = false;
				} else {
					toast.error("Не удалось выполнить запрос.", {
						autoClose: 4000,
					});
				}
			},

			async trashEntry(entry) {
				const succeeded = await this.$api.downloadables.trash(entry.id);

				if(succeeded) {
					toast.success("Материал успешно отправлен в корзину.", {
						autoClose: 4000,
					});

					entry.isDeleted = true;
				} else {
					toast.error("Не удалось выполнить запрос.", {
						autoClose: 4000,
					});
				}
			},

			async restoreEntry(entry) {
				const succeeded = await this.$api.downloadables.restore(entry.id);

				if(succeeded) {
					toast.success("Материал успешно восстановлен из корзины.", {
						autoClose: 4000,
					});

					entry.isDeleted = false;
				} else {
					toast.error("Не удалось выполнить запрос.", {
						autoClose: 4000,
					});
				}
			},

			async eraseEntry(entry) {
				const succeeded = await this.$api.downloadables.erase(entry.id);

				if(succeeded) {
					toast.success("Материал успешно стёрт.", {
						autoClose: 4000,
					});
				} else {
					toast.error("Не удалось выполнить запрос.", {
						autoClose: 4000,
					});
				}
			}
    },

    components: {
			ConfirmationModal
    },

		emits: ['toggle:visibility', 'toggle:removal']
  }
</script>

<style></style>