<template>
	<div class="row" v-if="entryData.step == 3">
		<h2>Подписание договора</h2>

		<p>
			Требуется подписать договор с нашей стороны и отправить пользователю в формате PDF.
		</p>

		<div class="container">
			<div class="row">
				<div class="col-12 col-xl-3">
					<label class="form-label">Утверждённый договор</label>
					<button type="button" class="btn btn-contract-download" @click="downloadContract">Скачать договор</button>
				</div>
				<div class="col-12 col-xl-3">
					<div class="w-100">
						<label class="form-label">Подписанный договор</label>
						<input class="form-control form-control-lg" accept="application/pdf" type="file" name="contract-file" @change="handleFileChange" />
					</div>
				</div>
				<div class="col-12 col-xl-3">
					<label class="form-label">&nbsp;&nbsp;&nbsp;&nbsp;</label>
					<button type="button" class="d-block btn btn-contract-submit" @click="submitContract">Отправить договор</button>
				</div>
			</div>
		</div>
	</div>
	<div class="row" v-else>
		<h2>Подписание договора</h2>

		<div>
			Договор подписан со стороны OG MUSE.
		</div>

		<div v-if="entryData.step > 4">
			Договор подписан со стороны клиента.
		</div>
	</div>
</template>

<script>
	import { toast } from 'vue3-toastify';
	import 'vue3-toastify/dist/index.css';

  export default {
		props: {
			entryData: {
				type: Object
			}
		},

    data () {
      return {
				signedContract: null
      }
    },

    mounted() {
			
    },

    methods: {
			downloadContract() {
				// Logic to download the contract PDF
				this.$api.licenses.downloadInitial(this.entryData.id);
			},
			
			handleFileChange(event) {
				// Logic to handle the selected file
				this.signedContract = event.target.files[0];
			},

			async submitContract() {
				try {
					if (!this.signedContract) {
						console.error('No signed contract file selected.');
						return;
					}

					const formData = new FormData();
					formData.append('contractFile', this.signedContract);
					formData.append('entryId', this.entryData.id);

					const response = await this.$api.licenses.firstSign(formData);

					toast.success('Договор успешно загружен!');

					this.$emit('contract:sent');

					// console.log('Contract submitted successfully:', response);
				} catch (error) {
					toast.error('Не удалось отправить договор. Попробуйте ещё раз.');
					// console.error('Contract submission failed:', error);
					// You could also notify the user about the failure (e.g., using a toast or alert)
				}
			}
		},

    components: {
			
    },

		emits: ['contract:sent']
  }
</script>

<style></style>