import receivedNews from "../resources/news/received";
import sentNews from "../resources/news/sent";
import newsAsOption from "../resources/news/option";

const URI_NAME = 'news';

export default function(instance) {
	return {
		all(page = 0, query = '', onlyTrashed = false, onlyHidden = false) {
			let query_string = [];

			if(query.length > 0) {
				query_string.push(['name', query]);
			}

			if(page !== 0) {
				query_string.push(['page', page]);
			}

			if(onlyTrashed)
				query_string.push(['onlyTrashed', true]);

			if(onlyHidden)
				query_string.push(['onlyHidden', true]);

			query_string = query_string.map(item => item.join('=')).join('&');
			
			return instance.get(`${URI_NAME}?${query_string}`)
				.then(response => {
					response.data.data = response.data.data.map(receivedNews);
					return response.data;
				}
			);
		},

		asOptions(query = '') {
			return this.all(0, query).then(({ data }) => data.map(newsAsOption));
		},

		entry(id) {
			return id ? instance.get(`${URI_NAME}/${id}`)
													.then(response => receivedNews(response.data))
								: receivedNews();
		},

		update(id, previous, current) {
			return instance.patch(`${URI_NAME}/${id}`, sentNews(current, previous, true)).then(response => response.data);
		},

		store(entry) {
			return instance.post(URI_NAME, sentNews(entry, null, false)).then(response => response.data);
		},

		hide(id) {
			return instance.patch(`${URI_NAME}/${id}`, { hidden: true }).then(response => response.status === 204);
		},

		reveal(id) {
			return instance.patch(`${URI_NAME}/${id}`, { hidden: false }).then(response => response.status === 204);
		},

		trash(id) {
			return instance.delete(`${URI_NAME}/${id}`).then(response => response.status === 200);
		},

		restore(id) {
			return instance.put(`${URI_NAME}/${id}/restore`).then(response => response.status === 204);
		},

		erase(id) {
			return instance.delete(`${URI_NAME}/${id}/force`).then(response => response.status === 200);
		},

		upload(payload) {
			if(!payload) {
				console.error("Error: Nothing to upload.");
				return
			}

			return instance.post(`${URI_NAME}/upload`, payload).then(response => response.data);
		},

		async uploadChunks(payload, attempts) {
			if(!payload) {
				console.error("Error: Nothing to upload.");
				return;
			}

			const uploadChunk = async (payload, attempts = 3) => {
				try {
					const response = await instance.post(`${URI_NAME}/uploadChunks`, payload, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					});
					return response.data; // Successfully uploaded.
				} catch(error) {
					if(error.response && error.response.status === 429) {
						if(attempts > 0) {
							const retryAfter = error.response.headers['retry-after'] 
																	? parseInt(error.response.headers['retry-after']) * 1000 
																	: 1000; // Default retry time (1 second)
	
							await new Promise(resolve => setTimeout(resolve, retryAfter)); // Wait for the specified time
							return uploadChunk(payload, attempts - 1); // Retry uploading
						} else {
							console.error("Error 429: Too Many Requests. Max attempts reached for chunk.");
							throw error; // Rethrow the error after max attempts
						}
					}
					throw error; // Rethrow other errors
				}
			}

			await uploadChunk(payload,attempts);
		}
	}
}