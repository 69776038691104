<template>
	<div class="row" v-if="entryData.step == 5">
		<h2>Оплата договора</h2>

		<p>
			Требуется проверить подписанный клиентом договор и разрешить клиенту оплатить заказ, если всё в порядке.
		</p>

		<div class="container">
			<div class="row">
				<div class="col-12 col-xl-3">
					<label class="form-label">Подписанный договор</label>
					<button type="button" class="btn btn-contract-download" @click="downloadContract">Скачать договор</button>
				</div>
				<div class="col-12 col-xl-3">
					<label class="form-label">&nbsp;&nbsp;&nbsp;&nbsp;</label>
					<button type="button" class="d-block btn btn-contract-submit" @click="allowPayment">Одобрить на оплату</button>
				</div>
				<div class="col-12 col-xl-3">
					<label class="form-label">&nbsp;&nbsp;&nbsp;&nbsp;</label>
					<button type="button" class="d-block btn btn-contract-submit" @click="requestChanges">Отправить на доработку</button>
				</div>
			</div>
		</div>
	</div>
	<div class="row" v-else>
		<h2>Оплата договора</h2>

		<div>
			Договор одобрен OG MUSE для оплаты.
		</div>

		<div v-if="entryData.step >= 7">
			Договор полностью оплачен клиентом.
		</div>
	</div>
</template>

<script>
	import { toast } from 'vue3-toastify';
	import 'vue3-toastify/dist/index.css';

  export default {
		props: {
			entryData: {
				type: Object
			}
		},

    data () {
      return {

      }
    },

    mounted() {
			
    },

    methods: {
			downloadContract() {
				this.$api.licenses.downloadSigned2(this.entryData.id);
			},

			async allowPayment() {
				try {
					const response = await this.$api.licenses.allowPayment(this.entryData.id);

					toast.success('Договор успешно одобрен!');

					this.$emit('contract:approved');
					// this.$emit('contract:rejected');

					// console.log('Contract submitted successfully:', response);
				} catch (error) {
					toast.error('Не удалось отправить запрос. Попробуйте ещё раз.');
					// console.error('Contract submission failed:', error);
					// You could also notify the user about the failure (e.g., using a toast or alert)
				}
			},

			async requestChanges() {
				try {
					const response = await this.$api.licenses.requestChanges(this.entryData.id);

					toast.success('Договор успешно отправлен на доработку!');

					this.$emit('contract:rejected');
					// this.$emit('contract:rejected');

					// console.log('Contract submitted successfully:', response);
				} catch (error) {
					toast.error('Не удалось отправить запрос. Попробуйте ещё раз.');
					// console.error('Contract submission failed:', error);
					// You could also notify the user about the failure (e.g., using a toast or alert)
				}
			},
		},

    components: {
			
    },

		emits: ['contract:approved', 'contract:rejected']
  }
</script>

<style></style>