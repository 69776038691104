export default function() {
	return {
		id: 								null,
		title:							'',
		slug:								'',
		summary:						'',
		cover:							null,
		body:								'',
		isHidden:						false,
		isDeleted:					false,
		media:							[],
		keywords:						[],
		covers:							{
													covers: [],
													uploaded: true
												}
	}
}