<template>
	<div class="track-purchase-data"><span class="track-purchase-title">{{ title }}</span><span
		class="track-purchase-subtitle">уже полностью подготовлен к отправке.</span></div>
	<div role="alert" class="fade alert alert-1 show">Каждая покупка в магазине OG Muse
	вдохновляет его авторов на создание новых произведений и помогает OG Muse продолжать радовать слушателей
	современными авторскими хитами и качественной музыкой.</div>
	<div role="alert" class="fade alert alert-2 show"><strong>Мы всегда на связи:</strong> наша поддержка поможет
		Вам в разрешении любых вопросов по OG Muse. Если вопрос касается купленного
		трека, необходимо указать номер заказа.
	</div>
	<button type="button" class="purchase-modal-pay-button btn d-flex align-items-center gap-3" @click="$emit('proceed')">
		<i class="fa-solid fa-money-bill-wave"></i> Оплатить {{ Number(price).toFixed(2) }} ₽
	</button>
</template>

<script>

export default {
	props: {
		title: {
			type: String
		},
		price: {
			type: Number
		}
	},

	data () {
		return {

		}
	},

	mounted() {

	},

	methods: {

	},

	components: {

	},

	emits: ['proceed']
}
</script>

<style>
</style>