<template>
	<data-form
		v-if="mounted"
		:id="id"
		:endpoints="$api.downloadables"
	>
		<template #heading="{ isNewEntry }">
			{{ isNewEntry ? 'Создание продаваемого товара' : 'Изменение продаваемого товара' }}
		</template>

		<template #form="{ resource, hints }">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12 col-xxl-8">
						<h3>Основные данные</h3>
						<div class="container-fluid">
							<div class="row gy-3">
								<!-- Название продаваемого файла -->
								<div class="col-12">
									<Input
										title="Название продаваемого файла"
										placeholder="Укажите название продаваемого файла"
										v-model="resource.title"
									>
									</Input>
								</div>

								<!-- Файл материала -->
								<div class="col-12">
									<label class="form-label">
										Привязанный файл
									</label>
									<div class="d-flex justify-content-start gap-3 align-items-center">
										<div>
											<file-processor
												@done="(file, start, end) => attachFile(resource, file, start, end)"
											>
												<i class="fa-solid fa-upload"></i>
											</file-processor>
										</div>
										<div>
											<audio-player
												:file="resource.file"
											/>
										</div>
										<div>
											<file-downloader 
												:id="resource.id"
											/>
										</div>
									</div>
								</div>

								<!-- Текст песни -->
								<div class="col-12">
									<Input
										type="textarea"
										title="Текст песни"
										placeholder="Укажите текст песни"
										v-model="resource.lyrics"
									>
									</Input>
								</div>
							</div>
						</div>
					</div>
					
					<div class="col-12 col-xxl-4">
						<h3>Доступные уровни лицензий</h3>

						<div class="d-flex flex-column justify-content-start align-items-start gap-3">
							<div>
								<Checkbox
									title="Стандартная"
									v-model="resource.licenses.basicEnabled"
									:disabled="resource.licenses.businessEnabled"
									@update:modelValue="newValue => onLicenseTierSelect(resource, 'basic', newValue)"
								/>
							</div>
							<div>
								<Checkbox
									title="Расширенная"
									v-model="resource.licenses.extendedEnabled"
									:disabled="resource.licenses.businessEnabled"
									@update:modelValue="newValue => onLicenseTierSelect(resource, 'extended', newValue)"
								/>
							</div>
							<div>
								<Checkbox
									title="Премиум"
									v-model="resource.licenses.premiumEnabled"
									:disabled="resource.licenses.businessEnabled"
									@update:modelValue="newValue => onLicenseTierSelect(resource, 'premium', newValue)"
								/>
							</div>
							<div>
								<Checkbox
									title="Бизнес"
									v-model="resource.licenses.businessEnabled"
									:disabled="resource.licenses.basicEnabled || resource.licenses.extendedEnabled || resource.licenses.premiumEnabled"
									@update:modelValue="newValue => onLicenseTierSelect(resource, 'business', newValue)"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</template>
	</data-form>
</template>

<script>
	import DataForm from '../../ui/DataForm.vue';
	import Input from '../../generic/input/Input.vue';
	import Checkbox from '../../generic/input/Checkbox.vue';
	import TagInput from '../../generic/input/TagInput.vue';
	import FileProcessor from '../../ui/FileProcessor.vue';
	import AudioPlayer from '../../ui/AudioPlayer.vue';
	import FileDownloader from '../../ui/FileDownloader.vue';
	import PanelSection from '../../ui/PanelSection.vue';
	import slugify from 'slugify';

  export default {
    data () {
      return {
				categories: [],
				measureUnits: [],
				mounted: false,
				id: null
      }
    },

    mounted() {
			this.id = this.$el.parentNode.dataset.id;
			this.mounted = true;
    },

    methods: {
			onLicenseTierSelect(resource, tierName, newValue) {
				if(tierName === 'business') {
					resource.licenses.basicEnabled 		= false;
					resource.licenses.extendedEnabled = false;
					resource.licenses.premiumEnabled 	= false;
				} else {
					resource.licenses.businessEnabled = false;
				}
			},

			slugFromTitle(resource) {
				resource.slug = slugify(resource.title, {
					lower: true,
					strict: true,
					trim: true
				});
			},

			updateCovers(resource, crops) {
				resource.covers = {
					covers: crops,
					uploaded: false
				};
			},

			attachFile(resource, file, start, end) {
				resource.file  = file;
				resource.start = start;
				resource.end   = end;
				// console.log('received file, start: ', start, ', end: ', end);
			},
    },

    components: {
			DataForm, Input, Checkbox, TagInput, FileProcessor, AudioPlayer, FileDownloader, PanelSection
    }
  }
</script>

<style></style>