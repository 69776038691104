export default function(entry, previousState, updatedOnly = true) {
	const updatedFields = {};

	if(previousState === null) {
		return Object.assign({}, {
			title:							entry.title,
			file:								entry.file,
			type:								entry.type,
			start:							entry.start,
			end:								entry.end,
			lyrics:							entry.lyrics,
			basicEnabled:				entry.licenses.basicEnabled,
			extendedEnabled:		entry.licenses.extendedEnabled,
			premiumEnabled:			entry.licenses.premiumEnabled,
			businessEnabled:		entry.licenses.businessEnabled,
		});
	}

	if(previousState.title !== entry.title || !updatedOnly)
		updatedFields.title = entry.title;

	if(previousState.file !== entry.file || !updatedOnly)
		updatedFields.file = entry.file;

	if(previousState.type !== entry.type || !updatedOnly)
		updatedFields.type = entry.type;

	if(previousState.start !== entry.start || !updatedOnly)
		updatedFields.start = entry.start;

	if(previousState.end !== entry.end || !updatedOnly)
		updatedFields.end = entry.end;

	if(previousState.lyrics !== entry.lyrics || !updatedOnly)
		updatedFields.lyrics = entry.lyrics;

	if(previousState.licenses.basicEnabled !== entry.licenses.basicEnabled || !updatedOnly)
		updatedFields.basicEnabled = entry.licenses.basicEnabled;

	if(previousState.licenses.extendedEnabled !== entry.licenses.extendedEnabled || !updatedOnly)
		updatedFields.extendedEnabled = entry.licenses.extendedEnabled;

	if(previousState.licenses.premiumEnabled !== entry.licenses.premiumEnabled || !updatedOnly)
		updatedFields.premiumEnabled = entry.licenses.premiumEnabled;

	if(previousState.licenses.businessEnabled !== entry.licenses.businessEnabled || !updatedOnly)
		updatedFields.businessEnabled = entry.licenses.businessEnabled;

	return updatedFields;
}