<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<Input
					title="Заголовок"
					placeholder="Введите заголовок для этого файла"
					v-model="modelValue.title"
					@update:model-value="updateTitle"
				/>
			</div>

			<div class="col-12">
				<Input
					type="textarea"
					:rows="4"
					title="Описание"
					placeholder="Опишите ваш файл подробнее"
					v-model="modelValue.description"
					@update:model-value="updateDescription"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import Input from '../../../generic/input/Input.vue';

	export default {
		props: {
			modelValue: {
				type: Object,
				default: () => ({}),
				required: true
			}
		},

		data () {
			return {

			}
		},

		mounted() {
			if (!this.modelValue) {
				console.warn('The modelValue prop is required but was not provided.');
			}
		},

		methods: {
			updateTitle(value) {
				this.$emit('update:modelValue', { ...this.modelValue, title: value });
			},

			updateDescription(value) {
				this.$emit('update:modelValue', { ...this.modelValue, description: value });
			}
		},

		components: {
			Input
		},

		emits: ['update:modelValue']
	}
</script>

<style>
</style>