<template>
	<template v-if="loading">
		Загрузка...
	</template>
	<template v-else>
		<!-- At this point we are guaranteed that downloadable and sellable exist and have data. -->
		<div class="modal-aside">
			<div class="title-block"><span id="checkoutModalLabel">Ваш чек</span></div>
			<div class="number-block"><strong>Номер заказа:</strong><br><span>
				<span v-if="screen === 'guest'">не присвоен</span>
				<span v-else>{{ chequeId || "OG-XXXX-XXX-XX" }}</span></span></div>
			<div class="date-block"><strong>Дата заказа:</strong><br><span><!-- 18.11.2022 21:32:14 -->{{ new Date().toLocaleString('en-GB').replace(',', '') }}</span></div>
			<!-- <div class="specs-block"><strong>Характеристики:</strong><br><span>mp3, 8.95 Mb<br>320 kbps, 44100 kHz</span> 
			</div>-->
			<div class="sum-block">
				<strong>К оплате:</strong><br><span class="sum">{{ Number(cheque.price).toFixed(2) }} ₽</span>
				<br><span class="status">Ожидает оплаты</span>
			</div>
		</div>
		<div class="modal-content-wrapper">
			<template v-if="screen === 'guest'">
				<screen-guest />
			</template>

			<template v-if="screen === 'item-list'">
				<screen-item-list
					:items="cheque.downloadables"
					:title="sellable.title"
					:price="cheque.price"
					@proceed="switchScreen('payment')"
				/>
			</template>

			<!-- <template v-if="screen === 'pending'">
				<screen-pending
					:title="downloadable.title"
					:price="downloadable.unit.price"
					@proceed="switchScreen('payment')"
				/>
			</template> -->

			<template v-if="screen === 'payment'">
				<screen-payment
					:widgetId="confirmationToken"
					@proceed="switchScreen('success')"
					@error="switchScreen('error')"
				/>
			</template>

			<template v-if="screen === 'success'">
				<screen-success
					:urls="downloadableURLs"
					@proceed="refreshPage()"
				/>
			</template>

			<template v-if="screen === 'error'">
				<screen-error
				/>
			</template>
		</div>
	</template>
	
</template>

<script>
	// import Pending from './payment/Pending.vue';
	// import Payment from './payment/Payment.vue';
	// import Success from './payment/Success.vue';
	// import Error 	 from './payment/Error.vue';

	import Guest 	 	from './paymentBulk/Guest.vue';
	import ItemList from './paymentBulk/ItemList.vue';
	import Payment 	from './paymentBulk/Payment.vue';
	import Error		from './paymentBulk/Error.vue';
	import Success  from './paymentBulk/Success.vue';

export default {
	data () {
		return {
			loading: true,
			userId: null,
			sellableId: null,
			downloadableId: null,
			cheque: null,
			chequeId: null,
			purchaseId: null,
			confirmationToken: null,
			downloadableURLs: null,
			sellable: null,
			downloadable: null,
			screen: 'item-list'
		}
	},

	mounted() {
		// this.downloadableId = this.$el.parentNode.dataset.downloadable;
		this.sellableId 		= this.$el.parentNode.dataset.sellable;
		this.userId					= window.userId;

		const paymentModal = document.getElementById('bulkCheckoutModal-' + this.sellableId);
		paymentModal.addEventListener('show.bs.modal', event => {
			// this.switchScreen('pending');
			this.retrieve();
		});

		paymentModal.addEventListener('hidden.bs.modal', event => {
			this.loading = true;
		});
	},

	methods: {
		async retrieve() {
			try {
				if(!this.sellable) {
					this.sellable 					= await this.$api.sellables.entry(this.sellableId);
					this.sellable.price			= this.sellable.downloadables.map(({ price }) => price).reduce((pv, cv) => pv + cv, 0);
				}

				this.cheque = {};

				// for (const downloadable of this.sellable.downloadables) {
				// 	const current_downloadable = await this.$api.downloadables.entry(downloadable.id);
					
				// 	downloadable.title = current_downloadable.title;
				// 	// console.log(current_downloadable);
				// }
				if(this.userId) {
					this.chequeId						= (await this.$api.sellables.registerBulkPurchase(this.userId, this.sellableId)).id;

					this.cheque 						= await this.$api.cheques.entry(this.chequeId);
					this.cheque.price				= this.cheque.downloadables.map(({ price }) => price).reduce((pv, cv) => parseFloat(pv) + parseFloat(cv), 0).toFixed(2);
				
				// console.log(this.sellable.downloadables);

				// if(!this.downloadable) {
				// 	this.downloadable 			= await this.$api.downloadables.entry(this.downloadableId);
				// 	this.downloadable.unit	= this.sellable.downloadables.find((element) => element.id === this.downloadableId);
				// }
				
				// this.cheque 						= await this.$api.sellables.registerBulkPurchase(this.userId, this.sellableId)
				// this.chequeId						= (await this.$api.sellables.registerPurchase(this.userId, this.sellableId, this.downloadableId)).id;
				
					let paymentData					= await this.$api.sellables.issueBulkPurchase(this.chequeId);
					this.confirmationToken	= paymentData.confirmation_token;
					this.paymentId					= paymentData.id;
				} else {
					this.cheque.price	= this.sellable.price;
					this.switchScreen('guest');
				}

				this.loading 						= false;
			} catch(error) {
				console.log(error);
				console.error(`Entry not found`);
			}
		},

		async switchScreen(newScreen) {
			if(newScreen === 'success') {
				this.downloadableURLs = await this.$api.sellables.finalizeBulkPurchase(this.chequeId);
			}
			this.screen = newScreen;
		},

		async refreshPage() {
			window.location.reload(true);
		}
	},

	components: {
		ScreenGuest: Guest,
		ScreenItemList: ItemList,
		ScreenPayment: Payment,
		ScreenSuccess: Success,
		ScreenError: Error
		// ScreenPending: Pending,
		// ScreenPayment: Payment,
		// ScreenSuccess: Success,
		// ScreenError: Error
	}
}
</script>