<template>
	<div class="track-purchase-data"><span class="track-purchase-title">Купить полностью товар &laquo;{{ title }}&raquo;</span><span
		class="track-purchase-subtitle">Вы получите весь товар в личный кабинет.</span></div>
	<div role="alert" class="fade alert alert-1 show">Каждая покупка в магазине OG Muse
	вдохновляет его авторов на создание новых произведений и помогает OG Muse продолжать радовать слушателей
	современными авторскими хитами и качественной музыкой.</div>

	<div role="alert" class="fade alert alert-2 show">
		<table class="table caption-top">
			<caption>Покупаемые товары</caption>
			<thead>
				<tr>
					<th scope="col">Название</th>
					<th scope="col">Цена</th>
				</tr>
			</thead>
			<tbody v-if="items">
				<tr v-for="item in items">
					<td>{{ item.title }}</td>
					<td>{{ item.price }} ₽</td>
				</tr>
			</tbody>
		</table>
	</div>

		<button type="button" class="purchase-modal-pay-button btn d-flex align-items-center gap-3" @click="$emit('proceed')">
		<i class="fa-solid fa-money-bill-wave"></i> Оплатить {{ Number(price).toFixed(2) }} ₽
	</button>
</template>

<script>

export default {
	props: {
		items: {
			type: Array,
			default: []
		},
		title: {
			type: String
		},
		price: {
			type: Number
		}
	},

	data () {
		return {

		}
	},

	mounted() {
		// console.log(this.items)
	},

	methods: {

	},

	components: {

	},

	emits: ['proceed']
}
</script>

<style>
</style>