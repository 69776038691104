<template>
	<div class="row">
		<div class="col-12 col-xl-4">
			<h3>Данные физлица</h3>

			<ul>
				<li><strong>ФИО:</strong> <br/> {{ entryData.idv_last_name }} {{ entryData.idv_first_name }} {{ entryData.idv_patronymic }}</li>
				<li><strong>Пол</strong>: <br/> {{ entryData.idv_gender == '1' ? "Мужской" : "Женский" }}</li>
				<li><strong>Дата рождения</strong>: <br/> {{ entryData.idv_birthday.format('DD.MM.YYYY') }}</li>
				<li><strong>ИНН</strong>: <br/> {{ entryData.idv_itn }}</li>
			</ul>
		</div>

		<div class="col-12 col-xl-4">
			<h3>Паспортные данные</h3>

			<ul>
				<li><strong>Серия и номер паспорта</strong>: <br/> {{ entryData.idv_passport_number }}</li>
				<li><strong>Орган, выдавший паспорт</strong>: <br/> {{ entryData.idv_passport_issued_by }}</li>
				<li><strong>Код подразделения</strong>: <br/> {{ entryData.idv_passport_issued_code }}</li>
				<li><strong>Дата выдачи</strong>: <br/> {{ entryData.idv_passport_issued_on.format('DD.MM.YYYY') }}</li>
				<li><strong>Адрес регистрации</strong>: <br/> {{ entryData.idv_passport_registered_at }}</li>
			</ul>
		</div>

		<div class="col-12 col-xl-4">
			<h3>Контактные данные</h3>

			<ul>
				<li><strong>Номер телефона</strong>: <br/> {{ entryData.idv_phone }}</li>
				<li><strong>Электронная почта</strong>: <br/> {{ entryData.idv_email }}</li>
			</ul>
		</div>
	</div>
</template>

<script>
  export default {
		props: {
			entryData: {
				type: Object
			}
		},

    data () {
      return {

      }
    },

    mounted() {
			
    },

    methods: {

    },

    components: {
			
    },

		emits: []
  }
</script>

<style></style>