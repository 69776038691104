import dayjs from 'dayjs';
import receivedUser from '../user/received';
import receivedDownloadable from '../downloadable/received';
import defaults from './defaults';

export default function(entry) {
	return entry ? {
		id																					: entry.id,
		item																				: receivedDownloadable(entry.item),
		client																			: receivedUser(entry.client),
		step																				: entry.step,
		license_type 																: entry.details.license_type,
		actor 																			: entry.details.actor,
		idv_last_name 															: entry.details.idv_last_name,
		idv_first_name 															: entry.details.idv_first_name,
		idv_patronymic 															: entry.details.idv_patronymic,
		idv_gender 																	: entry.details.idv_gender,
		idv_birthday 																: dayjs(entry.details.idv_birthday),
		idv_itn 																		: entry.details.idv_itn,
		idv_phone 																	: entry.details.idv_phone,
		idv_email 																	: entry.details.idv_email,
		idv_passport_number 												: entry.details.idv_passport_number,
		idv_passport_issued_by 											: entry.details.idv_passport_issued_by,
		idv_passport_issued_code 										: entry.details.idv_passport_issued_code,
		idv_passport_issued_on 											: dayjs(entry.details.idv_passport_issued_on),
		idv_passport_registered_at 									: entry.details.idv_passport_registered_at,
		ent_full_title 															: entry.details.ent_full_title,
		ent_short_title 														: entry.details.ent_short_title,
		ent_representative_last_name 								: entry.details.ent_representative_last_name,
		ent_representative_first_name 							: entry.details.ent_representative_first_name,
		ent_representative_patronymic 							: entry.details.ent_representative_patronymic,
		ent_representative_itn 											: entry.details.ent_representative_itn,
		ent_representative_kpp 											: entry.details.ent_representative_kpp,
		ent_representative_phone 										: entry.details.ent_representative_phone,
		ent_representative_email 										: entry.details.ent_representative_email,
		ent_representative_ogrn 										: entry.details.ent_representative_ogrn,
		ent_registered_at 													: entry.details.ent_registered_at,
		ent_representative_passport_number 					: entry.details.ent_representative_passport_number,
		ent_representative_passport_issued_by 			: entry.details.ent_representative_passport_issued_by,
		ent_representative_passport_issued_code 		: entry.details.ent_representative_passport_issued_code,
		ent_representative_passport_issued_on 			: dayjs(entry.details.ent_representative_passport_issued_on),
		ent_representative_passport_registered_at		: entry.details.ent_representative_passport_registered_at,
		createdAt																		:	dayjs(entry.createdAt),
		updatedAt																		:	dayjs(entry.updatedAt)
	} : defaults();
}