<template>
	<!-- <div class="track-purchase-data"><span class="track-purchase-title">{{ title }}</span><span
		class="track-purchase-subtitle">уже полностью подготовлен к отправке.</span></div>
	<div role="alert" class="fade alert alert-1 show">Каждая покупка в магазине OG Muse
	вдохновляет его авторов на создание новых произведений и помогает OG Muse продолжать радовать слушателей
	современными авторскими хитами и качественной музыкой.</div>
	<div role="alert" class="fade alert alert-2 show">Приготовьте <strong>действующий адрес электронной
		почты</strong> или <strong>Telegram / WhatsApp / VK</strong> для ввода после оплаты заказа для получения
	трека.<br><br>
	<div class="modal-alert-aux"><strong>Обратите внимание:</strong> указывая почту, лучше всего указать адрес или
		Яндекс.Почты, или Gmail, отличающиеся стабильностью в скорости доставки писем.<br><br>Наша поддерfжка поможет
		Вам в разрешении любых вопросов по OG Muse в Вашем любимом мессенджере. Если вопрос касается купленного
		трека, необходимо указать номер заказа.</div>
	</div><button type="button" class="purchase-modal-pay-button btn d-flex align-items-center gap-3" @click="$emit('proceed')">
		<i class="fa-solid fa-money-bill-wave"></i> Оплатить {{ price }} ₽
	</button> -->

	<div class="container-fluid">
		<h2>Выберите уровень лицензии</h2>

		<div class="container license-tier-picker-wrapper">
			<div class="row row-cols-1 row-cols-lg-2 row-cols-xxl-4 g-3 gy-4">
				<div class="col">
					<div class="card license-card h-100">
						<div class="card-body">
							<h2 class="card-title">Стандартная</h2>
							<div class="tier-price">2 200 ₽</div>
						</div>
						<div class="card-footer">
							<button class="btn btn-pick-tier w-100">Выбрать</button>
						</div>
					</div>
				</div>

				<div class="col">
					<div class="card license-card active h-100">
						<div class="card-body">
							<h2 class="card-title">Расширенная</h2>
							<div class="tier-price">4 400 ₽</div>
						</div>
						<div class="card-footer">
							<button disabled class="btn btn-disabled btn-pick-tier w-100">Выбрано</button>
						</div>
					</div>
				</div>

				<div class="col">
					<div class="card license-card h-100">
						<div class="card-body">
							<h2 class="card-title">Премиум</h2>
							<div class="tier-price">13 200 ₽</div>
						</div>
						<div class="card-footer">
							<button class="btn btn-pick-tier w-100">Выбрать</button>
						</div>
					</div>
				</div>

				<div class="col">
					<div class="card license-card h-100">
						<div class="card-body">
							<h2 class="card-title">Бизнес</h2>
							<div class="tier-price">350 000 ₽</div>
						</div>
						<div class="card-footer">
							<button class="btn btn-pick-tier w-100">Выбрать</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <h2>Данные для договора</h2>

		<div class="text-muted">
			Перед оплатой заполните поля данной формы, чтобы мы правильно оформили ваш лицензионный договор.
		</div>

		

		<div class="row">
			<div class="col-12 col-lg-4">
				<Input
					title="Фамилия"
					placeholder="Иванов"
					v-model="surname"
				>
				</Input>
			</div>

			<div class="col-12 col-lg-4">
				<Input
					title="Имя"
					placeholder="Иван"
					v-model="name"
				>
				</Input>
			</div>

			<div class="col-12 col-lg-4">
				<Input
					title="Отчество"
					placeholder="Иванович"
					v-model="patronymic"
				>
				</Input>
			</div>

			<div class="col-12">
				<Input
					title="Название лицензируемой композиции"
					disabled
					:placeholder="title"
				>
				</Input>
			</div>

			<div class="col-12 col-lg-4">
				<Input
					disabled
					title="Сумма к оплате"
					placeholder="13500.00"
				>
				</Input>
			</div>
		</div> -->
	</div>
</template>

<script>
	import Input from '../../../generic/input/Input.vue';

export default {
	props: {
		title: {
			type: String
		},
		price: {
			type: Number
		}
	},

	data () {
		return {
			name: null,
			surname: null,
			patronymic: null,
		}
	},

	mounted() {

	},

	methods: {

	},

	components: {
		Input
	},

	emits: ['proceed']
}
</script>

<style>
</style>