<template>
	<button type="button" :disabled="!this.file" @click.prevent="toggle" class="btn btn-sellable-audio-play play-button" :class="{'playing': playing}">
		<i v-if="this.playing" class="fa-solid fa-pause"></i>
		<i v-else class="fa-solid fa-play"></i>
	</button>
</template>

<script>
import { nextTick } from 'vue';

export default {
	props: {
		file: {
			type: String
		}
	},

	data () {
		return {
			audio: null,
			playing: false,
			endedEvent: null
		}
	},

	watch: {
		file: {
			handler(newFile) {
				if(newFile) {
					if(this.endedEvent) {
						this.audio.removeEventListener("ended", this.endedEvent);
					}

					this.audio = new Audio(newFile);

					this.endedEvent = (event) => {
						this.audio.pause();
						this.playing = false;
						this.audio.currentTime = 0;
					};

					this.audio.addEventListener("ended", this.endedEvent);
				}
			},
			immediate: true
		}
  },

	mounted() {
		this.$audioBus.on(() => {
			if(this.audio) {
				this.audio.pause();
				this.playing = false;
				this.audio.currentTime = 0;
			}
		});

		window.addEventListener('beforeunload', () => {
			if(this.audio) {
				this.audio.pause();
				this.playing = false;
				this.audio.currentTime = 0;
			}
		});
	},

	methods: {
		async toggle() {
			if(this.audio) {
				if(this.audio.paused) {
					this.$audioBus.emit('');

					await nextTick();

					this.audio.play();
					this.playing = true;
				} else {
					this.audio.pause();
					this.playing = false;
					this.audio.currentTime = 0;
				}
			}
		}
	},

	components: {

	}
}
</script>

<style>
</style>