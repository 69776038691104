const liveAdminURL = 'https://ogmuse.ru/admin/',
			localAdminURL = `${import.meta.env.VITE_API_URL}/admin/`;

const createChunks = (blob, chunkSize) => {
	let chunks = [],
			start  = 0;

	while (start < blob.size) {
		let end 		= Math.min(start + chunkSize, blob.size),
				chunk		= blob.slice(start, end);

		chunks.push(chunk);
		start = end;
	}

	return chunks;
}

const getBlobFileExtension = (blob) => {
	const mimeType = blob.type;
	let extension = '';

	if (mimeType) {
			const mimeTypeExtensionMap = {
					'image/jpeg': 'jpg',
					'image/png': 'png',
					'image/gif': 'gif',
					'image/webp': 'webp',
					'application/pdf': 'pdf',
					'text/plain': 'txt',
					'text/csv': 'csv',
					'application/zip': 'zip',
					'video/mp4': 'mp4'
					// Add more mappings as needed
			};

			extension = mimeTypeExtensionMap[mimeType] || ''; // Default to empty if not found
	}

	return extension;
}


export default {
	install: (app, options) => {
		// let baseURL = 'https://ogmuse.ru/admin/'; // 'http://192.168.0.159:8000/admin/';

		let baseURL = liveAdminURL; //localAdminURL;

		app.config.globalProperties.$helpers = ({
			createChunks,
			getBlobFileExtension,

			sendBlobUploadRequest: async (requestFunction, uploadedBlob, uploadId) => {
				const chunks 		= createChunks(uploadedBlob, 1024 * 1024); // 1 MB per chunk

				// let uploadedCount = 0;
				// uploadedBlob.uploadProgress = 0;

				// Limit the concurrency to 3 chunks at a time
				const CONCURRENCY_LIMIT = 3;
				const queue = [];

				for (let i = 0; i < chunks.length; i++) {
					const formData = new FormData();
					formData.append('id', uploadId);
					formData.append('extension', getBlobFileExtension(uploadedBlob));
					formData.append('chunk', chunks[i]);
					formData.append('chunkNumber', i + 1);
        	formData.append('totalChunks', chunks.length);

					console.log(`Chunk #${i+1}:`, {
						id: uploadId,
						extension: getBlobFileExtension(uploadedBlob),
						chunk: chunks[i],
						chunkNumber: i+1,
						totalChunks: chunks.length
					});
				
					const promise = requestFunction(formData)
						// .then(() => {
						// 	// uploadedCount++;
						// 	// // Calculate the percentage completed
						// 	// const percentageDone = ((uploadedCount / chunks.length) * 100).toFixed(2);
						// 	// uploadedBlob.uploadProgress = percentageDone; // Update progress on uploadedBlob
						// })
						.catch(error => console.error(`Error uploading chunk #${i + 1}:`, error));

					queue.push(promise);

					// When the queue reaches the concurrency limit, wait for the first promise to resolve
					if (queue.length === CONCURRENCY_LIMIT) {
						await Promise.race(queue);
						// Remove the resolved promise from the queue
						queue.splice(queue.findIndex(p => p === promise), 1);
					}
				}

				// Wait for all remaining uploads to finish
				await Promise.all(queue);
			},

			sendUploadRequest: async (requestFunction, uploadedFile) => {
				let fileData = await fetch(uploadedFile)
												.then(res => uploadedFile.includes('base64') ? res.blob() : res);

				const data = new FormData();
				data.append('file', new File([fileData], "upload"));

				return requestFunction(data);
			},

			sendDownloadRequest: async (downloadableId) => {
				return app.config.globalProperties.$api.downloadables.download(downloadableId);
			},

			linkTo: (url) => {
				return baseURL + url;
			},

			formatFileSize: (bytes, decimalPoint) => {
				if(bytes == 0) return '0 B';
				var k = 1000,
						dm = decimalPoint || 2,
						sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
						i = Math.floor(Math.log(bytes) / Math.log(k));
				return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
		 	}
		});
	}
}