<template>
	<template v-if="loading">
		Загрузка...
	</template>
	<template v-else>
		<!-- At this point we are guaranteed that downloadable and sellable exist and have data. -->
		<div class="modal-aside">
			<div class="title-block"><span id="licenseModalLabel">Ваш чек</span></div>
			<div class="number-block"><strong>Номер заказа:</strong><br><span><!-- OG-XXXX-XXX-XX -->{{ chequeId }}</span></div>
			<div class="date-block"><strong>Дата заказа:</strong><br><span><!-- 18.11.2022 21:32:14 -->{{ new Date().toLocaleString('en-GB').replace(',', '') }}</span></div>
			<!-- <div class="specs-block"><strong>Характеристики:</strong><br><span>mp3, 8.95 Mb<br>320 kbps, 44100 kHz</span> 
			</div>-->
			<div class="sum-block">
				<strong>К оплате:</strong><br><span class="sum">{{ downloadable.unit.price }} ₽</span>
				<br><span class="status">Ожидает оплаты</span>
			</div>
		</div>
		<div class="modal-content-wrapper">
			<template v-if="screen === 'details'">
				<screen-details
					:title="downloadable.title"
					:price="downloadable.unit.price"
					@proceed="switchScreen('payment')"
				/>
			</template>
		</div>
	</template>
	
</template>

<script>
	import Details from './license/Details.vue';

export default {
	data () {
		return {
			loading: true,
			userId: null,
			sellableId: null,
			downloadableId: null,
			chequeId: null,
			purchaseId: null,
			confirmationToken: null,
			downloadableURL: null,
			sellable: null,
			downloadable: null,
			screen: 'details'
		}
	},

	mounted() {
		this.downloadableId = this.$el.parentNode.dataset.downloadable;
		this.sellableId 		= this.$el.parentNode.dataset.sellable;
		this.userId					= window.userId;

		const paymentModal = document.getElementById('licenseModal-' + this.downloadableId);
		paymentModal.addEventListener('show.bs.modal', event => {
			this.switchScreen('details');
			this.retrieve();
		});

		paymentModal.addEventListener('hidden.bs.modal', event => {
			this.loading = true;
		});
	},

	methods: {
		async retrieve() {
			try {
				if(!this.sellable) {
					this.sellable 					= await this.$api.sellables.entry(this.sellableId);
				}

				if(!this.downloadable) {
					this.downloadable 			= await this.$api.downloadables.entry(this.downloadableId);
					this.downloadable.unit	= this.sellable.downloadables.find((element) => element.id === this.downloadableId);
				}
				
				// this.chequeId						= (await this.$api.sellables.registerPurchase(this.userId, this.sellableId, this.downloadableId)).id;
				
				// let paymentData					= await this.$api.sellables.issuePurchase(this.chequeId);
				// this.confirmationToken	= paymentData.confirmation_token;
				// this.paymentId					= paymentData.id;

				this.loading 						= false;
			} catch(error) {
				console.log(error);
				console.error(`Entry not found`);
			}
		},

		async switchScreen(newScreen) {
			// if(newScreen === 'success') {
			// 	this.downloadableURL = await this.$api.sellables.finalizePurchase(this.chequeId);
			// }
			this.screen = newScreen;
		},

		async refreshPage() {
			window.location.reload(true);
		}
	},

	components: {
		ScreenDetails: Details
	}
}
</script>

<style>
</style>