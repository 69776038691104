<template>
	<div class="row">
		<div class="col-12 col-xl-4">
			<h3>Данные представителя</h3>

			<ul>
				<li>
					<strong>ФИО</strong>: <br/> 
					{{ entryData.ent_representative_last_name }} {{ entryData.ent_representative_first_name }} {{ entryData.ent_representative_patronymic }}
				</li>
				<li>
					<strong>Номер паспорта</strong>: <br/> 
					{{ entryData.ent_representative_passport_number }}
				</li>
				<li>
					<strong>Орган, выдавший паспорт</strong>: <br/> 
					{{ entryData.ent_representative_passport_issued_by }}
				</li>
				<li>
					<strong>Код подразделения</strong>: <br/> 
					{{ entryData.ent_representative_passport_issued_code }}
				</li>
				<li>
					<strong>Дата выдачи</strong>: <br/> 
					{{ entryData.ent_representative_passport_issued_on.format('DD.MM.YYYY') }}
				</li>
				<li>
					<strong>Адрес регистрации</strong>: <br/> 
					{{ entryData.ent_representative_passport_registered_at }}
				</li>
			</ul>
		</div>

		<div class="col-12 col-xl-4">
			<h3>Данные организации</h3>

			<ul>
				<li>
					<strong>Полное наименование организации</strong>: <br/> 
					{{ entryData.ent_full_title }}
				</li>
				<li>
					<strong>Сокращённое наименование организации</strong>: <br/> 
					{{ entryData.ent_short_title }}
				</li>
				<li>
					<strong>ОГРН</strong>: <br/> 
					{{ entryData.ent_representative_ogrn }}
				</li>
				<li>
					<strong>ИНН</strong>: <br/> 
					{{ entryData.ent_representative_itn }}
				</li>
				<li>
					<strong>КПП</strong>: <br/> 
					{{ entryData.ent_representative_kpp }}
				</li>
				<li>
					<strong>Юридический адрес</strong>: <br/> 
					{{ entryData.ent_registered_at }}
				</li>
			</ul>
		</div>

		<div class="col-12 col-xl-4">
			<h3>Контактные данные</h3>
			<ul>
				<li>
					<strong>Номер телефона</strong>: <br/> 
					{{ entryData.ent_representative_phone }}
				</li>
				<li>
					<strong>Электронная почта</strong>: <br/> 
					{{ entryData.ent_representative_email }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
  export default {
		props: {
			entryData: {
				type: Object
			}
		},

    data () {
      return {

      }
    },

    mounted() {
			
    },

    methods: {

    },

    components: {
			
    },

		emits: []
  }
</script>

<style></style>