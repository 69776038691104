<template>
	<div class="row" v-if="entryData.step == 7">
		<h2>Договор активен</h2>

		<p>
			Здесь можно скачать лицензионный договор.
		</p>

		<div class="container">
			<div class="row">
				<div class="col-12 col-xl-3">
					<label class="form-label">Актуальный договор</label>
					<button type="button" class="btn btn-contract-download" @click="downloadContract">
						Скачать договор
					</button>
				</div>
				<div class="col-12 col-xl-3">

				</div>
				<div class="col-12 col-xl-3">

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { toast } from 'vue3-toastify';
	import 'vue3-toastify/dist/index.css';

  export default {
		props: {
			entryData: {
				type: Object
			}
		},

    data () {
      return {

      }
    },

    mounted() {
			
    },

    methods: {
			downloadContract() {
				this.$api.licenses.downloadSigned2(this.entryData.id);
			},
		},

    components: {
			
    },

		emits: ['contract:approved', 'contract:rejected']
  }
</script>

<style></style>