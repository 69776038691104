import './bootstrap';

import '../scss/app.scss';

import * as bootstrap from 'bootstrap';
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import { DateTime, TempusDominus } from '@eonasdan/tempus-dominus';
import intlTelInput from 'intl-tel-input/intlTelInputWithUtils';
import { ru as itiRu } from "intl-tel-input/i18n"; // Russian

import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/city/index.css';

import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import 'lightgallery/css/lightgallery.css';

import { createApp } from 'vue';

import api from './plugins/api';
import asset from './plugins/asset';
import helpers from './plugins/helpers';
import audioBus from './plugins/audioBus';

import UserList from './components/panel/User/List.vue';
import ArticleList from './components/panel/Article/List.vue';
import CategoryList from './components/panel/Category/List.vue';
import NewsList from './components/panel/News/List.vue';
import DownloadableList from './components/panel/Downloadable/List.vue';
import SellableList from './components/panel/Sellable/List.vue';
import ServiceList from './components/panel/Service/List.vue';
import ServiceDemoList from './components/panel/ServiceDemo/List.vue';
import TestimonialList from './components/panel/Testimonial/List.vue';
import LicenseOrderList from './components/panel/LicenseOrder/List.vue';

import UserForm from './components/panel/User/Form.vue';
import ArticleForm from './components/panel/Article/Form.vue';
import NewsForm from './components/panel/News/Form.vue';
import CategoryForm from './components/panel/Category/Form.vue';
import DownloadableForm from './components/panel/Downloadable/Form.vue';
import SellableForm from './components/panel/Sellable/Form.vue';
import ServiceForm from './components/panel/Service/Form.vue';
import ServiceDemoForm from './components/panel/ServiceDemo/Form.vue';
import TestimonialForm from './components/panel/Testimonial/Form.vue';
import LicenseOrderForm from './components/panel/LicenseOrder/Form.vue';

import AudioPlayerWidget from './components/frontend/widgets/AudioPlayer.vue';
import PaymentModal from './components/frontend/modal/PaymentModal.vue';
import BulkPaymentModal from './components/frontend/modal/BulkPaymentModal.vue';
import LicenseModal from './components/frontend/modal/LicenseModal.vue';

const mountNodeExists = (className) => document.getElementsByClassName(className).length > 0;

const mountIfExists = (componentName, className) => {
	if(mountNodeExists(className)) {
		const nodes = document.getElementsByClassName(className);

		for(let node of nodes) {
			const app = createApp(componentName).use(api).use(asset).use(helpers).use(audioBus);
			app.config.unwrapInjectedRef = true;
			app.mount(node);
		}
	}
};

const mountDatePickers = (className) => {
	if(mountNodeExists(className)) {
		const nodes = document.getElementsByClassName(className);

		for(let node of nodes) {
			new TempusDominus(node, {
				display: {
					components: {
						calendar: true,
						date: true,
						month: true,
						year: true,
						decades: false,
						clock: false,
						hours: false,
						minutes: false,
						seconds: false,
					},
					theme: "light"
				},
				localization: {
					today: 'Сегодняшняя дата',
					clear: 'Очистить',
					close: 'Закрыть',
					selectMonth: 'Выберите месяц',
					previousMonth: 'Предыдущий месяц',
					nextMonth: 'Следующий месяц',
					selectYear: 'Выберите год',
					previousYear: 'Предыдущий год',
					nextYear: 'Следующий год',
					selectDecade: 'Выберите десятилетие',
					previousDecade: 'Предыдущее десятилетие',
					nextDecade: 'Следующее десятилетие',
					previousCentury: 'Предыдущий век',
					nextCentury: 'Следующий век',
					pickHour: 'Выберите час',
					incrementHour: 'Добавить час',
					decrementHour: 'Убрать час',
					pickMinute: 'Выберите минуту',
					incrementMinute: 'Добавить минуту',
					decrementMinute: 'Убрать минуту',
					pickSecond: 'Выберите секунду',
					incrementSecond: 'Добавить секунду',
					decrementSecond: 'Убрать секунду',
					toggleMeridiem: 'Выбрать половину дня',
					selectTime: 'Выберите время',
					selectDate: 'Выберите дату',
					format: 'dd.MM.yyyy',
					locale: 'ru-RU'
				},
				restrictions: {
					maxDate: node.classList.contains('birthday-picker') ? ( new DateTime() ) : undefined
				}
			});
		}
	}
};

const mountPhoneInputs = (className) => {
	if(mountNodeExists(className)) {
		const nodes = document.getElementsByClassName(className);

		for(let node of nodes) {
			intlTelInput(node, {
				i18n: itiRu,
				initialCountry: "ru"
			});
		}
	}
};

window.addEventListener("DOMContentLoaded", (event) => {
	mountIfExists(UserList, "user-list");
	mountIfExists(ArticleList, "article-list");
	mountIfExists(CategoryList, "category-list");
	mountIfExists(NewsList, "news-list");
	mountIfExists(DownloadableList, "downloadable-list");
	mountIfExists(SellableList, "sellable-list");
	mountIfExists(ServiceList, "service-list");
	mountIfExists(ServiceDemoList, "service-demo-list");
	mountIfExists(TestimonialList, "testimonial-list");
	mountIfExists(LicenseOrderList, "license-list");

	mountIfExists(UserForm, "user-form");
	mountIfExists(ArticleForm, "article-form");
	mountIfExists(NewsForm, "news-form");
	mountIfExists(CategoryForm, "category-form");
	mountIfExists(DownloadableForm, "downloadable-form");
	mountIfExists(SellableForm, "sellable-form");
	mountIfExists(ServiceForm, "service-form");
	mountIfExists(ServiceDemoForm, "service-demo-form");
	mountIfExists(TestimonialForm, "testimonial-form");
	mountIfExists(LicenseOrderForm, "license-form");

	mountIfExists(AudioPlayerWidget, "audio-player");
	mountIfExists(PaymentModal, "payment-modal-app");
	mountIfExists(BulkPaymentModal, "bulk-payment-modal-app");
	mountIfExists(LicenseModal, "license-modal-app");

	mountDatePickers("date-picker");
	mountPhoneInputs("phone-field");
});

import '../sass/ckeditor.scss';

window.bootstrap = bootstrap;
window.cropper   = Cropper;