<template>
	<div class="media-uploader-wrapper">

		<div
			class="media-uploader-box"
			:class="{ _active: draggedOver }"
			@dragenter="debouncedDragEnter"
			@dragleave="debouncedDragLeave"
			@drop.prevent="onDrop"
			@dragover.prevent
		>

			<p>Перетащите файлы сюда</p>
			<p>Разрешены файлы форматов *.jpg и *.mp4</p>

		</div>

		<div class="media-uploader-items-wrapper" v-show="modelValue.length > 0">
			<h2>Загруженные файлы</h2>

			<div class="media-uploader-items row g-4 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4">
				<div v-for="(item, index) in modelValue" :key="item.id" class="col">
					<div class="card h-100">
						<div class="card-header">{{ printMediaTitle(item) }}</div>
						<div class="card-body">

							<template v-if="item.blob.type === 'image/jpeg'">
								<div class="item-image text-center">
									<img :src="item.url" :alt="printMediaTitle(item)" />
								</div>
							</template>
							<template v-else-if="item.blob.type === 'video/mp4'">
								<div class="item-image text-center">
									<img :src="item.thumbnail ?? videoThumbnailPlaceholder" :alt="printMediaTitle(item)" />
								</div>
							</template>
							<template v-else>
								Нераспознанный тип содержимого.
							</template>

							<div class="item-summary">
								<div class="item-title" :class="{ empty: item.title === null }">
									<h3>{{ printMediaTitle(item) }}</h3>
								</div>
								<div class="item-description" :class="{ empty: item.description === null }">
									{{ printMediaDescription(item) }}
								</div>

								<!-- <div>
									<strong>Размер:</strong> {{ $helpers.formatFileSize(item.blob.size) }}
								</div> -->
							</div>
						</div>
						<div class="card-footer">
							<div class="item-settings btn-group-vertical w-100">
								<button type="button" class="btn btn-primary" @click="openDetailsModal(item.id)">Редактировать</button>
								<button type="button" class="btn btn-danger" @click="removeMedia(item.id)">Удалить</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<modal
		ref="media-edit-modal"
		id="mediaEditingModal"
		size="lg"
		title="Детали"
		centered
	>
		<template #default>
			<details-component
				v-if="activeIndex >= 0"
				v-model="modelValue[activeIndex]"
				@update:model-value="updateMediaItem"
			/>
		</template>

		<template #actions>
			<button type="button" class="btn btn-primary" data-bs-dismiss="modal">Сохранить</button>
		</template>
	</modal>
</template>

<script>
	import Modal from '../generic/modal/Modal.vue';
	import Details from './partials/MediaUploader/Details.vue';
	import mediaItemTemplate from '../../api/templates/media/default';
	import debounce from 'lodash.debounce';

	export default {
		props: {
			modelValue: {
				type: Array,
				default: () => [],
				required: true,
				validator: (value) =>
					value.every((item) => 
								typeof item === 'object'
						&& 	item !== null 
						&& 	!Array.isArray(item)
					)
			}
		},

		data () {
			return {
				draggedOver: false,
				activeIndex: -1
			}
		},

		created() {
			this.debouncedDragEnter = debounce(this.onDragEnter, 80);
			this.debouncedDragLeave = debounce(this.onDragLeave, 80);
		},

		mounted() {
			if (!this.modelValue) {
				console.warn('The modelValue prop is required but was not provided.');
			}
		},

		computed: {
			videoThumbnailPlaceholder() {
				return this.$asset('/storage/images/video_thumbnail_placeholder.jpg');
			}
		},

		methods: {
			setActiveIndex(index) {
				if (index >= 0 && index < this.modelValue.length) {
					this.activeIndex = index;
				}
			},

			printMediaTitle(item) {
				return item.title ?? "Без названия";
			},

			printMediaDescription(item) {
				return item.description ?? "Без описания";
			},

			initMediaItem(blob) {
				return Object.assign(mediaItemTemplate(), {
					blob,
					url: URL.createObjectURL(blob)
				});
			},

			updateMediaItem(newValue) {
				this.$emit('update:modelValue', this.modelValue.map(
					(item, index) => 
						(index === this.activeIndex)
							? Object.assign(
								newValue, { 
									title: (newValue.title === "") ? null : newValue.title,
									description: (newValue.description === "") ? null : newValue.description
								})
							: item)
				);
			},

			removeMedia(id) {
				const idx = this.modelValue.findIndex(item => item.id === id);

				if (idx > -1 && idx < this.modelValue.length) {
					// Revoke the object URL to free memory
					URL.revokeObjectURL(this.modelValue[idx].url);
					this.$emit('update:modelValue', this.modelValue.filter((_, index) => index !== idx));
					console.log(`Removed media at index: ${idx}`);
				} else {
					console.error("Index out of bounds for removal");
				}
			},

			openDetailsModal(id) {
				this.setActiveIndex(this.modelValue.findIndex(item => item.id === id));
				this.$refs['media-edit-modal'].toggle();
			},

			// Event Handling

			onDragEnter() {
				if(!this.draggedOver)
					this.draggedOver = true;
			},

			onDragLeave() {
				if(this.draggedOver)
					this.draggedOver = false;
			},

			onDrop(event) {
				this.debouncedDragEnter.cancel();
				this.debouncedDragLeave.cancel();
				this.onDragLeave();

				const files = event.dataTransfer.files;

				const newMediaItems = [];

				for (const file of files) {
					// Файл или формата JPG, или формата MP4.
					if(["image/jpeg", "video/mp4"].includes(file.type)) {
						// Add media items.
						const mediaItem = this.initMediaItem(file);

						newMediaItems.push(mediaItem);
					} else {
						console.warn("Unsupported file type: ", file.type);
					}
				}

				this.$emit('update:modelValue', [
					...this.modelValue,
					...newMediaItems
				]);
			}
		},

		components: {
			Modal, DetailsComponent: Details
		},

		emits: ['update:modelValue']
	}
</script>

<style>
</style>