export default function() {
	return {
		id: 								null,
		title:							'',
		file:								'',
		type:								'',
		start:							0,
		end:								30,
		licenses:						{
			basicEnabled: false,
			extendedEnabled: false,
			premiumEnabled: false,
			businessEnabled: false,
		}
	}
}