import { customAlphabet } from 'nanoid';

const NUMBERS_STRING 		= "1234567890",
			UPPERCASE_LETTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
			LOWERCASE_LETTERS = UPPERCASE_LETTERS.toLowerCase(),
			SPECIAL_CHARS			= "-";

const CUSTOM_ALPHABET = (	NUMBERS_STRING
												+	UPPERCASE_LETTERS
												+ LOWERCASE_LETTERS
												+ SPECIAL_CHARS);

const DEFAULT_ID_LENGTH = 10;

const nanoid = customAlphabet(CUSTOM_ALPHABET, DEFAULT_ID_LENGTH);

const generateId = (length = DEFAULT_ID_LENGTH) => nanoid(length);

export { generateId };