export default function() {
	return {
		id																					:  null,
		item																				:  null,
		client																			:  null,
		step																				:  1,
		license_type 																: 'basic',
		actor 																			: 'individual',
		idv_last_name 															: '',
		idv_first_name 															: '',
		idv_patronymic 															: '',
		idv_gender 																	: '',
		idv_birthday 																: '',
		idv_itn 																		: '',
		idv_phone 																	: '',
		idv_email 																	: '',
		idv_passport_number 												: '',
		idv_passport_issued_by 											: '',
		idv_passport_issued_code 										: '',
		idv_passport_issued_on 											: '',
		idv_passport_registered_at 									: '',
		ent_full_title 															: '',
		ent_short_title 														: '',
		ent_representative_last_name 								: '',
		ent_representative_first_name 							: '',
		ent_representative_patronymic 							: '',
		ent_representative_itn 											: '',
		ent_representative_kpp 											: '',
		ent_representative_phone 										: '',
		ent_representative_email 										: '',
		ent_representative_ogrn 										: '',
		ent_registered_at 													: '',
		ent_representative_passport_number 					: '',
		ent_representative_passport_issued_by 			: '',
		ent_representative_passport_issued_code 		: '',
		ent_representative_passport_issued_on 			: '',
		ent_representative_passport_registered_at		: '',
	}
}