<template>
	<data-form
		v-if="mounted"
		:id="id"
		:endpoints="$api.licenses"
	>
		<template #heading="{ isNewEntry }">
			{{ isNewEntry ? 'Создание заказа' : 'Изменение заказа' }}
		</template>

		<template #form="{ resource, hints }">
			<div class="container-fluid">
				<div class="row gy-3">
					<div :class="{
						'alert': true,
						'alert-step-current': resource.step == 2,
						'alert-step-past': resource.step > 2
					}">
						<h2>Данные для договора</h2>

						<div class="container-fluid" v-if="resource.actor === 'individual'">
							<individual-data :entryData="resource" />
						</div>
						<div class="container-fluid" v-if="resource.actor === 'entity'">
							<entity-data :entryData="resource" />
						</div>

						<div class="text-center my-3">
							<div class="status-block d-inline-block p-3 rounded-3 fw-bold" v-if="resource.step == 2">
								Ожидаем, пока пользователь подтвердит договор
							</div>
						</div>
						
					</div>

					<div v-if="resource.step >= 3" :class="{
						'alert': true,
						'alert-step-current': resource.step == 3 || resource.step == 4,
						'alert-step-past': resource.step > 4
					}">
						<div class="container-fluid">
							<sign-contract :entryData="resource" @contract:sent="resource.step = 4" />
						</div>
						
						<div class="text-center my-3">
							<div class="status-block d-inline-block p-3 rounded-3 fw-bold" v-if="resource.step == 3">
								Как только договор будет подписан со стороны OG MUSE, клиент сразу получит уведомление. Изменить подписанный договор не будет возможно до ответного действия клиента.
							</div>
							<div class="status-block d-inline-block p-3 rounded-3 fw-bold" v-if="resource.step == 4">
								Ожидаем, пока клиент подпишет договор.
							</div>
						</div>
					</div>

					<div v-if="resource.step >= 5" :class="{
						'alert': true,
						'alert-step-current': resource.step == 5 || resource.step == 6,
						'alert-step-past': resource.step > 6
					}">
						<div class="container-fluid">
							<sign-checking :entryData="resource" @contract:approved="resource.step = 6" @contract:rejected="resource.step = 4" />
						</div>
						
						<div class="text-center my-3">
							<div class="status-block d-inline-block p-3 rounded-3 fw-bold" v-if="resource.step == 6">
								Ожидаем оплаты от клиента.
							</div>
						</div>
					</div>

					<div v-if="resource.step >= 7" :class="{
						'alert': true,
						'alert-step-current': resource.step >= 7,
						'alert-step-past': false
					}">
						<div class="container-fluid">
							<paid-contract :entryData="resource" />
						</div>

					</div>


				</div>
			</div>
		</template>
	</data-form>
</template>

<script>
	import DataForm from '../../ui/DataForm.vue';
	// import Input from '../../generic/input/Input.vue';
	import Checkbox from '../../generic/input/Checkbox.vue';
	import TagInput from '../../generic/input/TagInput.vue';
	import FileProcessor from '../../ui/FileProcessor.vue';
	import AudioPlayer from '../../ui/AudioPlayer.vue';
	import FileDownloader from '../../ui/FileDownloader.vue';
	import PanelSection from '../../ui/PanelSection.vue';
	import IndividualData from './components/IndividualData.vue';
	import EntityData from './components/EntityData.vue';
	import SignContract from './components/SignContract.vue';
	import SignChecking from './components/SignChecking.vue';
	import PaidContract from './components/PaidContract.vue';
	import slugify from 'slugify';

  export default {
    data () {
      return {
				mounted: false,
				id: null
      }
    },

    mounted() {
			this.id = this.$el.parentNode.dataset.id;
			this.mounted = true;
    },

    methods: {

    },

    components: {
			DataForm, Checkbox, TagInput, FileProcessor, AudioPlayer, FileDownloader, PanelSection, IndividualData, EntityData,
			SignContract, SignChecking, PaidContract
    }
  }
</script>

<style></style>