import receivedLicenseOrder from "../resources/licenseOrder/received";
import sentLicenseOrder from "../resources/licenseOrder/sent";
import licenseOrderAsOption from "../resources/licenseOrder/option";

const URI_NAME = 'licenseOrders';

export default function(instance) {
	return {
		all(page = 0, query = '', onlyTrashed = false, onlyHidden = false) {
			let query_string = [];

			if(query.length > 0) {
				query_string.push(['name', query]);
			}

			if(page !== 0) {
				query_string.push(['page', page]);
			}

			if(onlyTrashed)
				query_string.push(['onlyTrashed', true]);

			if(onlyHidden)
				query_string.push(['onlyHidden', true]);

			query_string = query_string.map(item => item.join('=')).join('&');
			
			return instance.get(`${URI_NAME}?${query_string}`)
				.then(response => {
					response.data.data = response.data.data.map(receivedLicenseOrder);
					return response.data;
				}
			);
		},

		asOptions(query = '') {
			return this.all(0, query).then(({ data }) => data.map(licenseOrderAsOption));
		},

		entry(id) {
			return id ? instance.get(`${URI_NAME}/${id}`)
													.then(response => receivedLicenseOrder(response.data))
								: receivedLicenseOrder();
		},

		update(id, previous, current) {
			return instance.patch(`${URI_NAME}/${id}`, sentLicenseOrder(current, previous, true)).then(response => response.data);
		},

		store(entry) {
			return instance.post(URI_NAME, sentLicenseOrder(entry, null, false)).then(response => response.data);
		},

		hide(id) {
			return instance.patch(`${URI_NAME}/${id}`, { hidden: true }).then(response => response.status === 204);
		},

		reveal(id) {
			return instance.patch(`${URI_NAME}/${id}`, { hidden: false }).then(response => response.status === 204);
		},

		trash(id) {
			return instance.delete(`${URI_NAME}/${id}`).then(response => response.status === 200);
		},

		restore(id) {
			return instance.put(`${URI_NAME}/${id}/restore`).then(response => response.status === 204);
		},

		erase(id) {
			return instance.delete(`${URI_NAME}/${id}/force`).then(response => response.status === 200);
		},

		download(id) {
			return instance.get(`${URI_NAME}/download/${id}`).then(response => response.data);
		},

		upload(payload) {
			if(!payload) {
				console.error("Error: Nothing to upload.");
				return
			}

			return instance.post(`${URI_NAME}/upload`, payload).then(response => response.data);
		},

		firstSign(formData) {
			return instance.post(`${URI_NAME}/firstSign`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then(response => response.data);
		},

		downloadInitial(id) {
			return instance.get(`${URI_NAME}/downloadInitial/${id}`, {
				responseType: 'blob', // Make sure to specify responseType for downloading files
			}).then((response) => {
				// Create a URL for the file
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `contract_initial_${id}.docx`); // Set a default file name
				document.body.appendChild(link);
				link.click(); // Trigger the download
				link.remove(); // Clean up
			});
		},

		downloadSigned2(id) {
			return instance.get(`${URI_NAME}/downloadSigned2/${id}`, {
				responseType: 'blob', // Make sure to specify responseType for downloading files
			}).then((response) => {
				// Create a URL for the file
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `contract_signed_2_${id}.pdf`); // Set a default file name
				document.body.appendChild(link);
				link.click(); // Trigger the download
				link.remove(); // Clean up
			});
		},

		allowPayment(id) {
			return instance.post(`${URI_NAME}/allowPayment/${id}`).then(response => response.data);
		},

		requestChanges(id) {
			return instance.post(`${URI_NAME}/requestChanges/${id}`).then(response => response.data);
		},
	}
}