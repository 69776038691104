import isEqual from 'lodash.isequal';
import sentDownloadable from './downloadable/sent';
import sentKeyword from '../keyword/sent';

export default function(entry, previousState, updatedOnly = true) {
	let updatedFields = {};

	const coverFields = {};

	if(entry.covers.uploaded === false) {
		for (const [resolution, url] of Object.entries(entry.covers.covers)) {
			coverFields[`cover_${resolution}`] = url;
		}
	}

	updatedFields = Object.assign({}, updatedFields, coverFields);

	if(previousState === null) {
		return Object.assign({}, coverFields, {
			title:							entry.title,
			slug:								entry.slug,
			summary:						entry.summary,
			cover:							entry.cover,
			category:						entry.category,
			hidden:							entry.hidden,
			isSingle:						entry.isSingle,
			moods:							entry.moods,
			downloadables:			entry.downloadables.map(sentDownloadable),
			keywords:						entry.keywords.map(sentKeyword),
		});
	}

	if(previousState.title !== entry.title || !updatedOnly)
		updatedFields.title = entry.title;

	if(previousState.slug !== entry.slug || !updatedOnly)
		updatedFields.slug = entry.slug;

	if(previousState.summary !== entry.summary || !updatedOnly)
		updatedFields.summary = entry.summary;

	if(previousState.cover !== entry.cover || !updatedOnly)
		updatedFields.cover = entry.cover;

	if(previousState.category !== entry.category || !updatedOnly)
		updatedFields.category = entry.category;

	if(previousState.hidden !== entry.hidden || !updatedOnly)
		updatedFields.hidden = entry.hidden;

	if(previousState.isSingle !== entry.isSingle || !updatedOnly)
		updatedFields.isSingle = entry.isSingle;

	if(!isEqual(previousState.downloadables, entry.downloadables) || !updatedOnly)
		updatedFields.downloadables = entry.downloadables.map(sentDownloadable);

	if(!isEqual(previousState.moods, entry.moods) || !updatedOnly)
		updatedFields.moods = entry.moods;

	if(!isEqual(previousState.keywords, entry.keywords) || !updatedOnly)
		updatedFields.keywords = entry.keywords.map(sentKeyword);

	return updatedFields;
}