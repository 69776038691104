export default function() {
	return {
		id: 								null,
		title:							'',
		summary:						'',
		cover:							'',
		hidden:							false,
		isSingle:						true,
		category:						null,
		moods:							[],
		keywords:						[],
		covers:							{
													covers: [],
													uploaded: true
												}
	}
}